<template>
  <section class='section'>
    <nav class="level filters">
      <div class="level-left">
        <div class="level-item">
          <h1>Ventas</h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <div class="control">
            <button
              class="button"
              type="button"
              :class="{ 'is-loading': isLoading }"
              @click="getCsvDownload"
            >
              Descargar
            </button>
          </div>
        </div>
        <div class="level-item">
          <div class="select is-fullwidth">
            <select class="select" v-model="filter" @change="filterByStatus">
              <option value="">Todos</option>
              <option value="1">PAGO</option>
              <option value="2">PEDIDO EN PROCESO</option>
              <option value="3">PEDIDO ENVIADO</option>
              <option value="4">DUDAS</option>
              <option value="5">PEDIDO ENTREGADO</option>
              <option value="6">Cancelada</option>
            </select>
          </div>
        </div>
        <div class="level-item">
          <div class="control">
            <input class="input" type="text" v-model="search" @input="searchSales" placeholder="Buscador...">
          </div>
        </div>
      </div>
    </nav>
    <div class="columns is-multiline">
      <div class="column is-12">
        <base-table
          class="is-bordered is-striped is-narrow is-fullwidth is-hoverable bankingTable"
          :columns="columnsData"
          :data="salesList"
          :loading=loading
        >
        </base-table>
      </div>
    </div>
    <base-pagination
      class="is-medium"
      :page="page"
      :pages="parseInt(pages)"
      :action="paginator"
      :loading="loading"
    />
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import mixin from '../../mixin/validateDate'
export default {
  mixins: [mixin],
  name: 'Ventas',
  components: {
    BaseTable: () => import('@/components/Table'),
    BasePagination: () => import('@/components/BasePagination')
  },
  data () {
    return {
      addUserModal: false,
      modalEditAdmin: false,
      page: 1,
      pages: '',
      loading: false,
      isLoading: false,
      search: '',
      filter: '',
      salesList: [],
      columnsData: [
        {
          id: 'id',
          header: '#',
          class: 'column-mobile',
          type: 'link',
          link: true,
          route: (row) => `ventas/detalle/${row.id}`
        },
        {
          id: 'createdAt',
          header: 'Fecha',
          class: 'column-mobile',
          accessor: (row) => this.formatDateTimestamp(row.createdAt)
        },
        { id: 'userNameBuyer',
          header: 'Comprador',
          class: 'column-mobile',
          type: 'link',
          link: true,
          route: (row) => `usuarios/detalle/${row.buyer_id}`
        },
        { id: 'userNameSeller',
          header: 'Vendedor',
          class: 'column-mobile',
          type: 'link',
          link: true,
          route: (row) => `usuarios/detalle/${row.seller_id}`
        },
        { id: 'name',
          header: 'Producto',
          class: 'column-mobile',
          type: 'link',
          link: true,
          route: (row) => `productos/detalle/${row.productId}`
        },
        { id: 'price', header: 'Monto', class: 'column-mobile', accessor: (row) => row.price ? (row.payment_type === 1 ? `$ ${parseInt(row.price).toLocaleString({ style: 'currency', currency: 'MXN' })}` : (row.payment_type === 2 ? `$ ${parseInt(row.price).toLocaleString({ style: 'currency', currency: 'MXN' })}` : 'N/A')) : 'N/A' },
        { id: 'status', header: 'Estado', class: 'column-mobile' }
      ]
    }
  },
  methods: {
    ...mapActions(['getsalesAPI', 'csvDownloadSales', 'addTemporalNotification']),
    paginator (page) {
      this.page = page
      this.loading = true
      this.getsalesAPI({ page, search: this.search, filter: this.filter })
        .then(resp => {
          this.loading = false
          this.salesList = resp.payload
          this.pages = resp.totalPages
        })
    },
    searchSales ({ target }) {
      this.page = 1
      if (target.value.length >= 3 || target.value.length === 0) {
        this.search = target.value
        this.getsalesAPI({ page: this.page, search: target.value, filter: this.filter })
          .then(resp => {
            this.salesList = resp.payload
            this.pages = resp.totalPages
            this.loading = false
          })
      }
    },
    filterByStatus ({ target }) {
      this.page = 1
      this.filter = target.value
      this.getsalesAPI({ page: this.page, search: this.search, filter: target.value })
        .then(resp => {
          this.salesList = resp.payload
          this.pages = resp.totalPages
          this.loading = false
        })
    },
    getCsvDownload () {
      this.isLoading = true
      this.csvDownloadSales({ search: this.search, filter: this.filter })
        .then(resp => {
          if (resp) {
            const exportName = 'Export sales.csv'
            const blob = new Blob([resp], { type: 'text/csv;charset=utf-8;' })
            if (navigator.msSaveBlob) {
              navigator.msSaveBlob(blob, exportName)
            } else {
              const link = document.createElement('a')
              if (link.download !== undefined) {
                const url = URL.createObjectURL(blob)
                link.setAttribute('href', url)
                link.setAttribute('download', exportName)
                link.style.visibility = 'hidden'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              }
            }
          } else {
            this.addTemporalNotification({ type: 'danger', message: 'Error en la descarga' })
          }
          this.isLoading = false
        })
    }
  },
  beforeMount () {
    this.loading = true
    this.getsalesAPI({ page: this.page })
      .then(resp => {
        this.salesList = resp.payload
        this.pages = resp.totalPages
        this.loading = false
      })
  }
}
</script>
<style scoped>
  .section {
    width: 100%;
    padding: 0 20px 0 20px;
  }
  .icon-action {
    cursor: pointer;
    color: #039DA1;
  }
</style>
